html {
  font-size: 13.33333vw;
}

body {
  margin: 0;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fade-enter {
  opacity: 0;
  /* transform: translateY(100%); */
}

.fade-enter-active {
  opacity: 1;
  /* transform: translateY(0); */
  transition: all 1000ms;
}

.fade-exit {
  opacity: 1;
  transform: translateX(-100%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(0);
  transition: all 1000ms;
}

.fp-tableCell {
  vertical-align: initial !important;
}

// @media screen and (max-width: 1024px) {
//   html {
//     font-size: 13.33333vw;
//   }
//   #root {
//     padding: 0;
//     :after,
//     :before {
//       box-sizing: initial;
//     }
//   }
// }

// *,
// :after,
// :before {
//   box-sizing: content-box !important;
// }
