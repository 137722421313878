@media screen and (max-width: 1024px) {
  .center_title_iphone {
    // color: #fff;
    // font-size: 0.18em;
    // margin-bottom: 12px;
    width: 2.05rem;
    height: 0.2rem;
    display: block;
  }
  .center_title {
    display: none;
  }
  .center_content {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.41rem;
    // background-color: aqua;
    // margin-top: 25px;
  }
  .center_iphone {
    // width: 90%;
    // margin: 0 auto;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // background-color: aqua;
  }
  // .center_left {
  //   width: 82%;
  // }

  .center_middle_1 {
    // width: 80%;
    width: 4.7rem !important;
    font-size: 0.16rem !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400 !important;
    color: #ffffff;
    line-height: 0.22rem !important;
    margin-top: 0.18rem !important;
    // opacity: 0.8;
  }
  .center_middle {
    width: 4.7rem;
    // margin: 0 auto;
    margin-top: 0.3rem;
    margin-left: 0.4rem;
    display: flex;
    justify-content: space-between;

    .center_middle_2 {
      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 0.22rem;
    }
  }

  .code {
    width: 0.9rem;
    height: 0.9rem;
    background-image: url('../../images/code.png');
    background-size: 100% 100%;
    margin: 0 auto;
  }
  .code_text {
    // height: 20px;
    font-size: 0.18rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 0.25rem;
    text-align: center;
    margin-top: 0.1rem;
  }

  .center {
    display: none;
  }
  .bottom_bottomText {
    width: 90%;
    margin: 0 auto;
    // height: 17px;
    font-size: 0.16rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    // line-height: 17px;
    opacity: 0.5;
    // border-top: 1px solid #ffffff;
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    padding-top: 0.24rem;
    padding-bottom: 0.3rem;
    margin-top: 0.4rem;
  }
  .bottom {
    width: 100%;
    // height: 348px;
    background: #002751;
    position: relative;
    .bottom_banner {
      width: 90%;
      margin: 0 auto;
      padding-top: 0.4rem;
      display: flex;
      div {
        height: 22px;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.33rem;
        padding-right: 0.4rem;
      }
    }
  }
}
// *********************************************************PC****************大于1024*******************************************************
@media (min-width: 1025px) {
  .center_iphone {
    display: none;
  }
  .center {
    width: 90%;
    height: 130px;
    margin: 0 auto;
    // background-color: saddlebrown;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
  }
  // .center_left {
  //   width: 90%;
  // }
  .center_title {
    // color: #fff;
    // font-size: 18px;
    // width: 90%;
    // margin: 0 auto;
    // margin-top: 51px;
    width: 205px;
    height: auto;
    display: block;
    margin-top: 51px;
    // margin-left: 78px;
  }
  .center_middle {
    width: 80%;
    // height: 130px;
    display: flex;
    justify-content: space-between;

    .center_middle_1 {
      width: 400px;
      // height: 80px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      // opacity: 0.8;
    }
    .center_middle_2 {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      // font-weight: 600;
      color: #ffffff;
      line-height: 20px;
    }
  }
  .center_right {
    // width: 20%;

    display: flex;
    flex-direction: column;
  }
  .code {
    width: 90px;
    height: 90px;
    background-image: url('../../images/code.png');
    background-size: 100% 100%;
    margin: 0 auto;
  }
  // .code img {
  //   width: 100%;
  //   height: 100%;
  // }
  .code_text {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    text-align: center;
    margin-top: 4px;
  }

  .bottom {
    width: 100%;
    // height: 348px;
    background: #002751;
    position: relative;
    .bottom_banner {
      width: 90%;
      margin: 0 auto;
      padding-top: 40px;
      display: flex;
      div {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        padding-right: 40px;
      }
    }
  }
  .bottom_bottomText {
    width: 90%;
    margin: 0 auto;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 17px;
    opacity: 0.5;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 29px;
    padding-bottom: 31px;
  }
}
