.PageCircleCmp {
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
  //- 媒体查询(1-760px)
  @media screen and (max-width: 760px) {
    .goBackFirst {
      color: #ccc;
      font-weight: 600;
      font-size: 0.12rem;
      margin-bottom: 0.2rem;
      text-align: center;
    }
    .circle {
      width: 0.48rem;
      height: 0.48rem;
      border-radius: 50%;
      // border: 2px solid #ffc107;
      border: 2px solid #ccc;
      color: #ccc;
      font-size: 0.18rem;
      text-align: center;
      line-height: 0.48rem;
      margin: 0 auto;
    }
    .arrowDown {
      color: #ccc;
      width: 15px;
      height: 15px;
      background-image: url('./../../images/pageCircle/chevron-down.png');
      background-size: cover;
      background-position: center center;
      margin: 0 auto;
    }
  }
  //- 媒体查询(761px-)
  @media screen and(min-width: 761px) {
    position: absolute;
    .goBackFirst {
      color: #ccc;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 5px;
      text-align: center;
    }
    .circle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      // border: 2px solid #ffc107;
      border: 2px solid #ccc;
      color: #ccc;
      font-size: 9px;
      text-align: center;
      line-height: 32px;
      margin: 0 auto;
    }
    .arrowDown {
      color: #ccc;
      width: 15px;
      height: 17px;
      background-image: url('./../../images/pageCircle/chevron-down.png');
      background-size: cover;
      background-position: center center;
      margin: 0 auto;
    }
  }
}
