//- 媒体查询(1-760px)
@media screen and (max-width: 760px) {
  .effectBox {
    box-sizing: border-box !important;
  }
  .closeImg {
    width: 0.4rem;
    height: 0.4rem;
    position: fixed;
    top: 0.95rem;
    right: 0.25rem;
    cursor: pointer;
  }
  .headBox {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  .leftInfo {
    display: none;
  }
  .contactUsPage {
    background: #44546a;
    min-height: 100vh;
    // overflow: scroll;
    padding: 0.4rem 0.74rem;
    font-size: 0.18rem;
    color: #fff;
    input {
      width: 100%;
      height: 0.8rem;
      background: none;
      outline: none;
      border: 1px solid #fff;
      box-sizing: border-box;
      color: #fff;
      caret-color: #00c3d2;
      font-size: 0.24rem;
      border-radius: 0.04rem;
      padding: 0.24rem;
    }
    input::-webkit-input-placeholder {
      color: #fff;
      opacity: 0.5;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #ccc;
      opacity: 0.5;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #ccc;
      opacity: 0.5;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #ccc;
      opacity: 0.5;
    }

    textarea {
      width: 100%;
      min-height: 2rem;
      background: none;
      outline: none;
      border: 1px solid #fff;
      box-sizing: border-box;
      color: #fff;
      caret-color: #00c3d2;
      font-size: 0.24rem;
      border-radius: 0.04rem;
      padding: 0.24rem;
    }
    textarea::-webkit-input-placeholder {
      color: #fff;
      opacity: 0.5;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    textarea:-moz-placeholder {
      color: #fff;
      opacity: 0.5;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    textarea::-moz-placeholder {
      color: #fff;
      opacity: 0.5;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    textarea::-ms-input-placeholder {
      color: #fff;
      opacity: 0.5;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    .contactUsTitle {
      color: #fff;
      text-align: center;
      .contactUsTitle_main {
        font-size: 0.4rem;
        font-weight: 600;
        margin-bottom: 0.2rem;
        padding-top: 0.7rem;
      }
      .contactUsTitle_sub {
        font-size: 0.18rem;
        line-height: 0.3rem;
        font-weight: 600;
      }
      .contactUsTitleSubBox {
        margin-top: 0.2rem;
        width: 100%;
        // height: 1.2rem;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 0.08rem;
      }
      .contactUsTitleSubBox {
        font-size: 0.18rem;
        padding: 0.27rem 0.7rem;
        box-sizing: border-box;
        .contactUsTitleSubBox_title {
          font-weight: 600;
          margin-bottom: 0.15rem;
        }
        .contactUsTitleSubBox_info {
          display: flex;
          justify-content: space-around;
        }
      }
    }
    .contactUsForm {
      margin-bottom: 0.8rem;
      .contactUsForm_item {
        margin-top: 0.4rem;
        .contactUsForm_itemName {
          font-size: 0.24rem;
          font-weight: 600;
          margin-bottom: 0.15rem;
        }
        .learnInput {
          border: 2px solid hsla(0, 0%, 100%, 0.5);
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 0.04rem;
          text-align: center;
          // line-height: 12px;
          font-size: 0.16rem;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 0.05rem;
          display: inline-block;
          border: none;
          outline: none;
        }

        .learnCheckbox_name {
          display: inline-block;
          vertical-align: middle;
          // line-height: 0.16rem;
          font-size: 0.14rem;
          font-weight: 600;
          // margin-right: 85px;
        }
        .contactUs_box {
          display: flex;
          justify-content: space-between;
        }
        .contactUsForm_checkboxItem {
          display: inline-block;
          // width: 50%;
          box-sizing: border-box;
          margin-bottom: 0.15rem;
          .contactUsForm_checkboxItem_box {
            display: inline-block;
            border: 0.015rem solid rgba(255, 255, 255, 0.5);
            width: 0.25rem;
            height: 0.25rem;
            border-radius: 0.04rem;
            text-align: center;
            line-height: 0.25rem;
            font-size: 0.16rem;
            vertical-align: middle;
          }
          .contactUsForm_checkboxItem_text {
            display: inline-block;
            vertical-align: middle;
            line-height: 0.25rem;
            font-size: 0.16rem;
            font-weight: 600;
            // margin-left: 0.1rem;
            // margin-right: 0.45rem;
          }
        }
      }
    }
    .contactUs_submitBox {
      position: relative;
      .contactUs_submitRegFailNotice {
        position: absolute;
        top: -0.42rem;
        left: 0;
        right: 0;
        text-align: center;
        color: #00c3d2;
        font-size: 0.24rem;
      }
      .contactUs_submit {
        color: #fff;
        font-size: 0.32rem;
        width: 4rem;
        height: 0.88rem;
        background: #ffc769;
        border-radius: 0.04rem;
        text-align: center;
        line-height: 0.88rem;
        cursor: pointer;
        margin: 0 auto;
        margin-bottom: 0.4rem;
      }
    }
  }
}
//- 媒体查询(761px-1440px)
@media screen and (min-width: 761px) {
  // .effectBox {
  //   box-sizing: border-box !important;
  // }
  .closeImg {
    width: 32px;
    height: 32px;
    position: fixed;
    top: 89px;
    right: 25px;
    cursor: pointer;
  }
  .headBox {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  .leftInfo {
    width: 120px;
    position: absolute;
    left: 60px;
    top: 176px;
    .leftInfo_title {
      font-size: 24px;
      margin-bottom: 20px;
      font-weight: 600;
    }
    .leftInfo_item {
      line-height: 22px;
    }
    .modalContentLeft_midLine {
      width: 121px;
      height: 2px;
      background: #fff;
      opacity: 0.4;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
      display: none;
    }
    .leftInfo_code {
      margin-top: 40px;
      text-align: left;
    }
  }
  .contactUsPage {
    background: #44546a;
    min-height: 100vh;
    // overflow: scroll;
    padding: 30px 0;
    font-size: 14px;
    color: #fff;
    input {
      width: 400px;
      height: 40px;
      background: none;
      outline: none;
      border: 1px solid #fff;
      box-sizing: border-box;
      color: #fff;
      caret-color: #00c3d2;
      font-size: 14px;
      border-radius: 4px;
      padding: 10px 16px;
    }
    input::-webkit-input-placeholder {
      color: #fff;
      opacity: 0.5;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #ccc;
      opacity: 0.5;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #ccc;
      opacity: 0.5;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #ccc;
      opacity: 0.5;
    }

    textarea::-webkit-input-placeholder {
      color: #fff;
      opacity: 0.5;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    textarea:-moz-placeholder {
      color: #fff;
      opacity: 0.5;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    textarea::-moz-placeholder {
      color: #fff;
      opacity: 0.5;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    textarea::-ms-input-placeholder {
      color: #fff;
      opacity: 0.5;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    textarea {
      width: 400px;
      min-height: 100px;
      background: none;
      outline: none;
      border: 1px solid #fff;
      box-sizing: border-box;
      color: #fff;
      caret-color: #00c3d2;
      font-size: 14px;
      border-radius: 4px;
      padding: 10px;
    }
    .contactUsTitle {
      color: #fff;
      text-align: center;
      .contactUsTitle_main {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 20px;
        padding-top: 70px;
      }
      .contactUsTitle_sub {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 50px;
        div {
          display: inline-block;
        }
      }
      .contactUsTitleSubBox {
        display: none;
      }
    }
    .contactUsForm {
      width: 400px;
      margin-bottom: 60px;
      margin: 0 auto;
      text-align: left;
      .contactUsForm_item {
        margin-top: 20px;
        .contactUsForm_itemName {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 14px;
        }
        .learnInput {
          display: inline-block;
          border: 2px solid hsla(0, 0%, 100%, 0.5);
          width: 16px;
          height: 16px;
          border-radius: 4px;
          text-align: center;
          line-height: 12px;
          font-size: 16px;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 5px;
        }
        .learnCheckbox_name {
          display: inline-block;
          vertical-align: middle;
          line-height: 16px;
          font-size: 14px;
          font-weight: 600;
          // margin-right: 85px;
        }
        .contactUs_box {
        }
        .contactUsForm_checkboxItem {
          display: inline-block;
          // width: 50%;
          box-sizing: border-box;
          margin-bottom: 15px;
          .contactUsForm_checkboxItem_box {
            display: inline-block;
            border: 2px solid rgba(255, 255, 255, 0.5);
            width: 14px;
            height: 14px;
            border-radius: 4px;
            text-align: center;
            line-height: 12px;
            font-size: 16px;
            vertical-align: middle;
            cursor: pointer;
          }
          .contactUsForm_checkboxItem_text {
            display: inline-block;
            vertical-align: middle;
            line-height: 20px;
            font-size: 14px;
            font-weight: 600;
            // margin-right: 35px;
            margin-right: 85px;
          }
        }
      }
    }
    .contactUs_submitBox {
      position: relative;
      .contactUs_submitRegFailNotice {
        position: absolute;
        top: -30px;
        left: 0;
        right: 0;
        text-align: center;
        color: #00c3d2;
        font-size: 14px;
      }
      .contactUs_submit {
        color: #fff;
        font-size: 14px;
        width: 140px;
        height: 40px;
        background: #ffc769;
        border-radius: 2px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
  }
}
