.singleMoleculeCmp {
  // width: 308px;
  // height: 234px;
  // transform: scale(1.5);
  position: relative;
  opacity: 0.2;

  .number {
    position: absolute;
    // transform: rotate(360deg);
  }
  .number:nth-child(1) {
    display: block;
    height: 35px;
    width: 35px;
    margin: 0;
    font-weight: 600;
    font-size: 32px;
    top: 30px;
    left: 20px;
    opacity: 0.3;

    -webkit-animation: hover_1 infinite 36s ease-in-out;
    animation: hover_1 infinite 36s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .number:nth-child(2) {
    display: block;
    // border-radius: 50%;
    font-weight: 600;
    height: 35px;
    width: 35px;
    margin: 0;
    font-size: 48px;
    top: 50px;
    left: 0px;
    opacity: 0.2;
    transform: rotate(360deg);
    -webkit-animation: hover_2 infinite 38s ease-in-out;
    animation: hover_2 infinite 38s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .number:nth-child(3) {
    display: block;
    // border-radius: 50%;
    font-weight: 600;
    font-size: 56px;
    height: 35px;
    width: 35px;
    margin: 0;
    top: 10px;
    left: 50px;
    opacity: 0.5;
    -webkit-animation: hover_3 infinite 45s ease-in-out;
    animation: hover_3 infinite 45s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .number:nth-child(4) {
    display: block;
    // border-radius: 50%;
    font-weight: 600;
    font-size: 28px;
    height: 35px;
    width: 35px;
    margin: 0;
    top: 10px;
    left: 50px;
    opacity: 0.2;

    -webkit-animation: hover_4 infinite 35s ease-in-out;
    animation: hover_4 infinite 35s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .number:nth-child(5) {
    display: block;
    // border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: 0;
    font-size: 32px;
    font-weight: 600;
    top: 400px;
    left: 320px;
    opacity: 0.3;
    -webkit-animation: hover_5 infinite 54s ease-in-out;
    animation: hover_5 infinite 54s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .number:nth-child(6) {
    display: block;
    // border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: 0;
    font-size: 32px;
    top: 370px;
    left: 420px;
    opacity: 0.3;
    -webkit-animation: hover_6 infinite 24s ease-in-out;
    animation: hover_6 infinite 24s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .number:nth-child(7) {
    display: block;
    // border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: 0;
    font-size: 32px;
    top: 160px;
    left: 50px;
    opacity: 0.3;
    -webkit-animation: hover_7 infinite 24s ease-in-out;
    animation: hover_7 infinite 24s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .number:nth-child(8) {
    display: block;
    // border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: 0;
    font-size: 64px;
    top: 180px;
    left: 90px;
    opacity: 0.2;
    font-weight: 600;
    -webkit-animation: hover_8 infinite 12s ease-in-out;
    animation: hover_8 infinite 12s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .number:nth-child(9) {
    display: block;
    // border-radius: 50%;
    height: 635px;
    width: 135px;
    margin: 0;
    font-size: 58px;
    top: 70px;
    left: 161px;
    opacity: 0.3;
    font-weight: 600;
    -webkit-animation: hover_9 infinite 14s ease-in-out;
    animation: hover_9 infinite 14s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .number:nth-child(10) {
    display: block;
    // border-radius: 50%;
    height: 135px;
    width: 435px;
    margin: 0;
    font-size: 32px;
    top: 570px;
    left: 122px;
    opacity: 0.3;
    -webkit-animation: hover_10 infinite 4s ease-in-out;
    animation: hover_10 infinite 4s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .number:nth-child(11) {
    display: block;
    // border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: 0;
    font-size: 32px;
    top: 90px;
    left: 120px;
    opacity: 0.3;
    -webkit-animation: hover_11 infinite 24s ease-in-out;
    animation: hover_11 infinite 24s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .number:nth-child(12) {
    font-weight: 600;
    display: block;
    // border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: 0;
    font-size: 72px;
    top: 250px;
    left: 720px;
    opacity: 0.3;
    -webkit-animation: hover_12 infinite 4s ease-in-out;
    animation: hover_12 infinite 4s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  @-webkit-keyframes hover_1 {
    0% {
      -webkit-transform: translateY(25px) rotate(30deg);
      transform: translateY(25px) rotate(30deg);
    }
    50% {
      -webkit-transform: translateY(333px) rotate(30deg);
      transform: translateY(333px) rotate(30deg);
    }
    0% {
      -webkit-transform: translatex(600px) rotate(30deg);
      transform: translatex(600px) rotate(30deg);
    }
  }
  @keyframes hover_1 {
    0% {
      -webkit-transform: translateY(25px) rotate(30deg);
      transform: translateY(25px) rotate(30deg);
    }
    50% {
      -webkit-transform: translateY(233px) rotate(30deg);
      transform: translateY(233px) rotate(30deg);
    }
    0% {
      -webkit-transform: translatex(600px) rotate(30deg);
      transform: translatex(600px) rotate(30deg);
    }
  }
  @-webkit-keyframes hover_2 {
    0% {
      -webkit-transform: translate(50px, 70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
    50% {
      -webkit-transform: translate(220px, 270px) rotate(30deg);
      transform: translate(520px, 270px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(50px, 70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
  }
  @keyframes hover_2 {
    0% {
      -webkit-transform: translate(50px, 70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
    50% {
      -webkit-transform: translate(220px, 270px) rotate(30deg);
      transform: translate(520px, 270px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(50px, 70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
  }
  @-webkit-keyframes hover_3 {
    0% {
      -webkit-transform: translate(10px, 50px) rotate(-30deg);
      transform: translate(10px, 50px) rotate(-30deg);
    }
    50% {
      -webkit-transform: translate(260px, 170px) rotate(-30deg);
      transform: translate(260px, 170px) rotate(-30deg);
    }
    100% {
      -webkit-transform: translate(10px, 50px) rotate(-30deg);
      transform: translate(10px, 50px) rotate(-30deg);
    }
  }
  @keyframes hover_3 {
    0% {
      -webkit-transform: translate(10px, 50px) rotate(-30deg);
      transform: translate(10px, 50px) rotate(-30deg);
    }
    50% {
      -webkit-transform: translate(260px, 170px) rotate(-30deg);
      transform: translate(260px, 170px) rotate(-30deg);
    }
    100% {
      -webkit-transform: translate(10px, 50px) rotate(-30deg);
      transform: translate(10px, 50px) rotate(-30deg);
    }
  }
  @-webkit-keyframes hover_4 {
    0% {
      -webkit-transform: translate(20px, 150px) rotate(-30deg);
      transform: translate(20px, 150px) rotate(-30deg);
    }
    50% {
      -webkit-transform: translate(20px, 70px) rotate(-30deg);
      transform: translate(20px, 70px) rotate(-30deg);
    }
    100% {
      -webkit-transform: translate(20px, 150px) rotate(-30deg);
      transform: translate(20px, 150px) rotate(-30deg);
    }
  }
  @keyframes hover_4 {
    0% {
      -webkit-transform: translate(20px, 150px) rotate(-30deg);
      transform: translate(20px, 150px) rotate(-30deg);
    }
    50% {
      -webkit-transform: translate(20px, 70px) rotate(-30deg);
      transform: translate(20px, 70px) rotate(-30deg);
    }
    100% {
      -webkit-transform: translate(20px, 150px) rotate(-30deg);
      transform: translate(20px, 150px) rotate(-30deg);
    }
  }
  @-webkit-keyframes hover_5 {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(30deg);
      transform: translate(0, 0) rotate(30deg);
    }
    10% {
      -webkit-transform: translate(420px, -12px) rotate(30deg);
      transform: translate(420px, -12px) rotate(30deg);
    }
    20% {
      -webkit-transform: translate(65px, -25px) rotate(30deg);
      transform: translate(65px, -25px) rotate(30deg);
    }
    30% {
      -webkit-transform: translate(100px, -40px) rotate(30deg);
      transform: translate(100px, -40px) rotate(30deg);
    }
    70% {
      -webkit-transform: translate(120px, 80px) rotate(30deg);
      transform: translate(120px, 80px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(30deg);
      transform: translate(0, 0) rotate(30deg);
    }
  }
  @keyframes hover_5 {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(30deg);
      transform: translate(0, 0) rotate(30deg);
    }
    10% {
      -webkit-transform: translate(420px, -12px) rotate(30deg);
      transform: translate(420px, -12px) rotate(30deg);
    }
    20% {
      -webkit-transform: translate(65px, -25px) rotate(30deg);
      transform: translate(65px, -25px) rotate(30deg);
    }
    30% {
      -webkit-transform: translate(100px, -40px) rotate(30deg);
      transform: translate(100px, -40px) rotate(30deg);
    }
    70% {
      -webkit-transform: translate(120px, 80px) rotate(30deg);
      transform: translate(120px, 80px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(0px, 0px) rotate(30deg);
      transform: translate(0, 0) rotate(30deg);
    }
  }
  @-webkit-keyframes hover_6 {
    50% {
      -webkit-transform: translateY(1px) rotate(-30deg);
      transform: translateY(-50px) rotate(-30deg);
    }
    100% {
      -webkit-transform: translatex(0px) rotate(-30deg);
      transform: translatex(0px);
    }
  }
  @keyframes hover_6 {
    50% {
      -webkit-transform: translateY(1px) rotate(-30deg);
      transform: translateY(-50px) rotate(-30deg);
    }
    100% {
      -webkit-transform: translatex(0px) rotate(-30deg);
      transform: translatex(0px);
    }
  }
  @-webkit-keyframes hover_7 {
    0% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, -70px) rotate(30deg);
    }
    50% {
      -webkit-transform: translate(220px, -120px) rotate(30deg);
      transform: translate(520px, -120px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, -70px) rotate(30deg);
    }
  }
  @keyframes hover_7 {
    0% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
    50% {
      -webkit-transform: translate(220px, -120px) rotate(30deg);
      transform: translate(520px, -120px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, -70px) rotate(30deg);
    }
  }
  @-webkit-keyframes hover_8 {
    0% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
    50% {
      -webkit-transform: translate(220px, -120px) rotate(30deg);
      transform: translate(520px, -120px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, -70px) rotate(30deg);
    }
  }
  @keyframes hover_8 {
    0% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
    50% {
      -webkit-transform: translate(220px, -120px) rotate(30deg);
      transform: translate(520px, -120px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, -70px) rotate(30deg);
    }
  }
  @-webkit-keyframes hover_9 {
    0% {
      -webkit-transform: translate(50px, 70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
    50% {
      -webkit-transform: translate(220px, 270px) rotate(30deg);
      transform: translate(520px, 270px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(50px, 70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
  }
  @keyframes hover_9 {
    0% {
      -webkit-transform: translate(50px, 70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
    50% {
      -webkit-transform: translate(220px, 270px) rotate(30deg);
      transform: translate(520px, 270px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(50px, 70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
  }
  @-webkit-keyframes hover_10 {
    0% {
      -webkit-transform: translate(-10px, -20px) rotate(30deg);
      transform: translate(-10px, -20px);
    }
    50% {
      -webkit-transform: translatex(40px) rotate(30deg);
      transform: translatex(40px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(-10px, -20px) rotate(30deg);
      transform: translate(-10px, -20px) rotate(30deg);
    }
  }
  @keyframes hover_10 {
    0% {
      -webkit-transform: translate(-10px, -20px) rotate(30deg);
      transform: translate(-10px, -20px) rotate(30deg);
    }
    50% {
      -webkit-transform: translatex(40px) rotate(30deg);
      transform: translatex(40px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(-10px, -20px) rotate(30deg);
      transform: translate(-10px, -20px) rotate(30deg);
    }
  }
  @-webkit-keyframes hover_11 {
    0% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
    50% {
      -webkit-transform: translate(220px, -120px) rotate(30deg);
      transform: translate(520px, -120px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, -70px) rotate(30deg);
    }
  }
  @keyframes hover_11 {
    0% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, 70px) rotate(30deg);
    }
    50% {
      -webkit-transform: translate(220px, -120px) rotate(30deg);
      transform: translate(520px, -120px) rotate(30deg);
    }
    100% {
      -webkit-transform: translate(50px, -70px) rotate(30deg);
      transform: translate(50px, -70px) rotate(30deg);
    }
  }
  @-webkit-keyframes hover_12 {
    50% {
      -webkit-transform: translateY(30px) rotate(-30deg);
      transform: translateY(30px) rotate(-30deg);
    }
    100% {
      -webkit-transform: translatex(10px) rotate(-30deg);
      transform: translatex(10px) rotate(-30deg);
    }
  }
  @keyframes hover_12 {
    50% {
      -webkit-transform: translateY(20px) rotate(-30deg);
      transform: translateY(20px) rotate(-30deg);
    }
    100% {
      -webkit-transform: translatex(10px) rotate(-30deg);
      transform: translatex(10px) rotate(-30deg);
    }
  }
}
