* {
  margin: 0;
  padding: 0;
}
.header {
  background-color: #fff;
  height: 64px;
  line-height: 64px;
}

//******************************************************************* iphone***********************************************************

@media screen and (max-width: 1024px) {
  .headBox {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  .center_text_1 {
    text-align: center;
    color: #333333;
    font-size: 0.18rem;
    margin-top: 0.4rem;
    padding-left: 6%;
    padding-right: 6%;
    // background-color: red;
  }
  .center_text_2 {
    text-align: center;
    color: #111111;
    font-size: 0.24rem;
    font-weight: bold;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 0.1rem;
    padding-bottom: 0.3rem;
  }
  .map {
    // width: 75vw;
    // height: 75vw;
    // width: 7.03rem;
    // height: 7rem;
    // background-image: url(../../images/map.png);
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // position: relative;
    // margin: 0 auto;
  }
  .bigCircleText {
    cursor: pointer;
    width: 0.28rem;
    height: 0.28rem;
  }
  .circle_selected {
    width: 28px;
    height: 28px;
    border: 2px solid #ffc769;
    filter: blur(4px);
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 55%;
    left: 81%;
  }
  .circle_selected_inside {
    width: 16px;
    height: 16px;
    background: #ffc769;
    // opacity: 0.4;
    border-radius: 50%;
  }
  .big_circle_place_name {
    height: 30px;
    font-size: 0.14rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 0.3rem;
  }
  // **********分割线***************
  .frame_selected {
    width: 100%;
    height: auto;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding-bottom: 0.39rem;
    padding-top: 0.59rem;
    // display: none;
    .box_closeLogo {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 2%;
      right: 2%;
    }
    .closeLogo {
      display: block;
      width: 100%;
      height: 100%;
    }
    .frame_selected_text {
      // height: 22px;
      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #999999;
      line-height: 0.22rem;
      text-align: center;
      // padding-top: 0.57rem;
    }
    .frame_selected_text_1 {
      // height: 40px;
      font-size: 0.28rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #004ea2;
      line-height: 0.2rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.1rem;
      margin-bottom: 0.3rem;
    }

    .addressLogo {
      width: 0.24rem;
      height: 0.24rem;
    }
    .frame_selected_detail {
      display: flex;
      justify-content: space-around;

      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.25rem;
      text-align: center;
    }
    .frame_selected_detail_number {
      display: flex;
      justify-content: space-around;
      font-size: 0.16rem;
      color: #333333;
      // font-weight: bold;
      margin-bottom: 0.2rem;
      text-align: center;
    }
  }
  .tip {
    width: 100%;
    height: 0.8rem;
    background-color: #ffc769;
    display: flex;
    text-align: center;
    justify-content: center;
    // flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: -1rem;
    z-index: 95;
    .tip_one {
      font-size: 0.18rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-right: 0.2rem;
      // line-height: 80px;
    }

    .tip_two {
      width: 1.5rem;
      height: 0.4rem;
      border-radius: 4px;
      border: 1px solid #ffffff;
      text-align: center;
      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 0.4rem;
      cursor: pointer;
    }
  }
  .title {
    width: 100%;
    height: 230px;
    background-image: url(../../images/learn-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0.7rem;
    .text {
      width: 3rem;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    .text1 {
      // width: 65%;
      font-size: 0.16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.24rem;
      text-align: center;
      padding: 0 42px 0 42px;
      padding-top: 0.2rem;
    }
  }

  // 未被选中的地区点
  .big_circle_text {
    display: flex;
    position: absolute;
    // align-items: center;
  }
  .circle_options_outside {
    width: 0.28rem;
    height: 0.28rem;
    // opacity: 0.4;
    // border: 2px solid #ffc769;
    // filter: blur(4px);
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    // top: 40%;
    // left: 30%;
    background-color: rgba(255, 199, 105, 0.1);
  }
  .newAnimate {
    animation: shake 2s infinite;
  }
  @keyframes shake {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.3);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.3);
    }
  }
  .circle_options_inside {
    // width: 0.16rem;
    // height: 0.16rem;
    width: 0.12rem;
    height: 0.12rem;
    border: 2px solid rgba(0, 195, 210, 1);
    box-shadow: 0 0 0.15rem #00c3d2;
    // opacity: 0.4;
    border-radius: 50%;
    // position: absolute;
    // top: 40%;
    // left: 30%;
  }

  .detail_big_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // padding-left: 3%;
    // box-sizing: border-box;
  }
  .detail_big_box_item {
    width: 31%;
    text-align: center;
  }
}

// ************************************ipad and pc*****************************************
@media (min-width: 1025px) {
  .headBox {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  .center_text_1 {
    text-align: center;
    color: #333333;
    font-size: 18px;
    margin-top: 80px;
  }
  .center_text_2 {
    text-align: center;
    color: #111111;
    font-size: 24px;
    font-weight: bold;
  }
  .map {
    // width: 66vw;
    // height: 66vw;

    // background-image: url(../../images/map.png);
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // width: 940px;
    // height: 940px;
    // position: relative;
    // margin: 0 auto;
    position: relative;
    top: 0;
  }
  .bigCircleText {
    cursor: pointer;
    width: 28px;
    height: 28px;
  }
  .circle_selected {
    // position: absolute;
    // width: 28px;
    // height: 28px;
    // background-color: #ffc769;
    // background-image: url(../../images/circle.png);
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // border-radius: 50%;

    width: 28px;
    height: 28px;

    border: 2px solid #ffc769;
    filter: blur(4px);
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 55%;
    left: 81%;
  }
  .circle_selected_inside {
    width: 16px;
    height: 16px;
    background: #ffc769;
    // opacity: 0.4;
    border-radius: 50%;
  }
  .frame_selected {
    width: 100%;
    height: auto;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    position: relative;
    top: 40px;
    padding-bottom: 39px;
    padding-top: 59px;
    // display: none;
    .box_closeLogo {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 2%;
      right: 2%;
      cursor: pointer;
    }
    .closeLogo {
      display: block;
    }
    .frame_selected_text {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #999999;
      line-height: 22px;
      text-align: center;
      // margin-top: 5%;
      // padding-top: 57px;
    }
    .frame_selected_text_1 {
      height: 40px;
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #004ea2;
      line-height: 40px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
      margin-bottom: 30px;
    }

    .addressLogo {
      width: 28px;
      height: 28px;
    }
    .frame_selected_detail {
      display: flex;
      justify-content: space-around;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 25px;
      text-align: center;
    }
    .frame_selected_detail_number {
      display: flex;
      justify-content: space-around;
      font-size: 32px;
      color: #333333;
      // font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
    }
    // .big_circle_place_name {
    //   // width: 64px;
    //   height: 30px;
    //   font-size: 24px;
    //   font-family: PingFangSC-Semibold, PingFang SC;
    //   font-weight: 600;
    //   color: #ffc769;
    //   line-height: 30px;
    // }
  }
  .tip {
    width: 100%;
    height: 80px;
    background-color: #ffc769;
    display: flex;
    text-align: center;
    justify-content: center;
    // flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: -152px;
    z-index: 95;
    .tip_one {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
    }

    .tip_two {
      width: 120px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #ffffff;

      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 40px;
      cursor: pointer;
    }
  }
  .title {
    width: 100%;
    height: 360px;
    background-image: url(../../images/learn-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
    .text {
      // text-align: center;
      // color: #ffffff;
      // font-size: 30px;
      width: 300px;
      height: auto;
      display: block;
    }
    .text1 {
      width: 66%;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      text-align: center;
      padding-top: 20px;
      // padding: 0 42px 0 42px;
    }
  }

  // 未被选中的地区点
  .big_circle_text {
    display: flex;
    position: absolute;
    // align-items: center;
  }
  .circle_options_outside {
    width: 28px;
    height: 28px;

    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    // border: 2px solid rgba(0, 195, 210, 0.1);
    // animation: shake 2s infinite;

    // filter: blur(4px);
    // top: 40%;
    // left: 30%;
    // background-color: rgba(255, 199, 105, 0.1);
    // opacity: 0.1;
    // border: 2px solid #ffc769;
    // filter: blur(4px);
    // background-color: #00c3d2;
  }
  @keyframes shake {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.5);
    }
    50% {
      transform: scale(0.5);
    }
    75% {
      transform: scale(1.5);
    }
  }
  .newAnimate {
    animation: shake 1.5s infinite;
  }

  .circle_options_inside {
    width: 12px;
    height: 12px;
    // background: #ffc769;
    // opacity: 0.4;
    border-radius: 50%;
    border: 2px solid rgba(0, 195, 210, 1);
    // box-shadow: 5px 5px 5px #00c3d2 inset;
    box-shadow: 0 0 15px #00c3d2;

    // position: absolute;
    // top: 40%;
    // left: 30%;
  }
  .big_circle_place_name {
    height: 30px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 30px;
  }
  .detail_big_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // padding-left: 3%;
    // box-sizing: border-box;
  }
  .detail_big_box_item {
    width: 31%;
    text-align: center;
  }
}
