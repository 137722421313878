@media screen and (max-width: 1024px) {
  .echartsMapClass {
    width: 7.03rem;
    height: 7rem;
    margin: 0 auto;
  }
}
@media (min-width: 1025px) {
  .echartsMapClass {
    width: 940px;
    height: 900px;
    margin: 0 auto;
  }
}
