// ******************************************************************iphone***************************************************************************************************
@media screen and (max-width: 1024px) {
  .headBox {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  .title {
    width: 100%;
    // height: 40vw;
    height: 3rem;
    background-image: url(../../images/pro_small.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-top: 0.7rem;
    .text {
      width: 65%;
      text-align: center;
      color: #ffffff;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 33px;
      // background-color: red;
    }
  }
  .middle_banner {
    width: 100%;
    height: 80px;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    line-height: 80px;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
  }
  .currently_selected {
    color: #004ea2;
  }
  .contentColor {
    background-color: #ffffff;
  }
  .middle_content {
    width: 86.13%;
    height: auto;
    margin: 0 auto;
    padding-top: 0.8rem;
    // padding-bottom: 20px;

    .middle_content_text1 {
      font-size: 0.32rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      text-align: center;
    }
    .middle_content_text2 {
      font-size: 0.2rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 0.4rem;
      margin-top: 0.3rem;
      padding-bottom: 0.8rem;
    }
    .middle_content_threeBox {
      // display: flex;
      width: 100%;
      justify-content: space-around;
      margin-top: 0.8rem;
      // margin-bottom: 100px;
      .middle_content_threeBox_item {
        // width: 90%;
        margin: 0 auto;
        // background: red;
      }
      .middle_content_threeBox_item_img {
        // width: 20.11%;
        width: 1.5rem;
        height: auto;
        display: block;
        margin: 0 auto;
      }
      .middle_content_threeBox_item_text {
        width: 100%;
        // height: 56px;
        font-size: 0.24rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 0.28rem;
        text-align: center;
        margin-top: 0.2rem;
        padding-bottom: 0.8rem;
      }
    }
  }
  .tip {
    width: 100%;
    height: 0.8rem;
    background-color: #ffc769;
    display: flex;
    text-align: center;
    justify-content: center;
    // flex-direction: column;
    align-items: center;
    .tip_one {
      font-size: 0.18rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-right: 0.32rem;
      // line-height: 80px;
    }

    .tip_two {
      width: 1.5rem;
      height: 0.4rem;
      border-radius: 4px;
      border: 1px solid #ffffff;
      text-align: center;
      font-size: 0.16rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 0.4rem;
    }
  }
  .smallBox_center {
    width: 100%;
    height: 0.7rem;
  }
}

// *********************************************************************ipad and pc***********************************************************************************************************
@media (min-width: 1025px) {
  .smallBox_center {
    width: 100%;
    height: 70px;
  }
  .headBox {
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  .title {
    width: 100%;
    height: 300px;
    background-image: url(../../images/pro-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-top: 70px;
    .text {
      text-align: center;
      color: #ffffff;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 45px;
    }
  }
  .middle_banner {
    width: 100%;
    height: 80px;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    line-height: 80px;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
  }
  .currently_selected {
    color: #004ea2;
  }
  .contentColor {
    background-color: #ffffff;
  }
  .middle_content {
    width: 80%;
    // background-color: aquamarine;
    margin: 0 auto;
    margin-top: 80px;
    .middle_content_text1 {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      text-align: center;
    }
    .middle_content_text2 {
      width: 65.27%;
      margin: 0 auto;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 80px;
    }
    .middle_content_threeBox {
      display: flex;
      width: 65.27%;
      margin: 0 auto;
      justify-content: space-between;
      margin-top: 60px;
      margin-bottom: 100px;
      .middle_content_threeBox_item {
        width: 25%;
        // background: red;
      }
      .middle_content_threeBox_item_img {
        width: 54%;
        height: auto;
        display: block;
        margin: 0 auto;
      }
      .middle_content_threeBox_item_text {
        width: 100%;
        height: 56px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;

        line-height: 28px;
        text-align: center;
        margin-top: 20px;
      }
    }
  }
  .tip {
    width: 100%;
    height: 80px;
    background-color: #ffc769;
    display: flex;
    text-align: center;
    justify-content: center;
    // flex-direction: column;
    align-items: center;
    .tip_one {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin-right: 20px;
    }

    .tip_two {
      width: 120px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #ffffff;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
