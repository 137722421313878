* {
  margin: 0;
  padding: 0;
}

.header {
  background-color: #fff;
  height: 64px;
  line-height: 64px;
}
@media (min-width: 1025px) {
  .select_content {
    display: none;
  }
  body {
    overflow-y: scroll;
  }
  .message {
    width: 65%;
    margin: 0 auto;
    .jobLogo {
      width: 100%;
      height: 140px;
      margin: 40px 0;
      display: block;
    }
    ul,
    li {
      padding: 0;
      margin: 0;
      // list-style: none;
      font-size: 14px;
      color: #666666;
      // padding-left: 1rem;
    }

    .message_content {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      .message_content_infor {
        width: 75%;
        // background-color: aqua;
        .content_infor_item {
          width: 100%;
          // height: 134px;
          border: 1px solid #e8e8e8;
          padding-bottom: 40px;
          margin-bottom: 20px;
          .open_details {
            margin-top: 20px;
            margin-right: 30px;
            text-align: right;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #004ea2;
            line-height: 17px;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            .open_details_grary {
              color: #999;
            }
          }
          .infor_title {
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #111111;
            line-height: 25px;
            padding-left: 5.7%;
            padding-top: 3px;
          }
          .infor_five {
            display: flex;
            padding-left: 5.7%;
            margin-top: 10px;
            .infor_salary {
              height: 16px;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #00c3d2;
              line-height: 16px;
              padding-right: 20px;
            }
            .infor_others {
              height: 16px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 16px;
              padding-left: 20px;
              padding-right: 20px;
              border-left: 1px solid rgba(204, 204, 204, 0.4);
            }
          }
        }
        .detail_describe {
          color: #111111;
          font-size: 14px;
          font-weight: bold;
          margin-top: 20px;
        }
        .infor_description_detail {
          font-size: 14px;
          color: #666;
          line-height: 28px;
          margin-right: 40px;
          margin-top: 20px;
        }
        .apply_job_button {
          width: 106px;
          height: 48px;
          background: #004ea2;
          border-radius: 2px;

          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 48px;
          text-align: center;
          margin-top: 20px;
          cursor: pointer;
          margin-left: -15px;
        }
      }
      .message_content_choose {
        width: 23%;
        background-color: #ffffff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // color: #666666;
        line-height: 22px;
        padding-bottom: 40px;
        border-radius: 2px;
        border: 1px solid #e8e8e8;
        .message_content_choose_item {
          padding-top: 20px;
          padding-left: 18%;
        }
        .choose_post_title {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 20px;
          margin-top: 20px;
        }
        .checkbox_text {
          margin-top: 10px;
          display: flex;
          align-items: center;
        }
        .checkbox_name {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
          margin-left: 8px;
        }
      }
    }
  }
  .icon_jiantou {
    font-size: 10px;
    margin-left: 4px;
  }
  .no_data {
    width: 193px;
    height: 324px;
    display: block;
    margin: 180px auto;

    img {
      width: 100%;
    }
    // font-size: 18px;
    // text-align: center;
    // color: #888;
    // line-height: 36px;
  }
  h4 {
    margin-left: -14px;
  }
}
@media screen and (max-width: 1024px) {
  body {
    background-color: #f1f1f1;
  }
  .iconSize {
    font-size: 0.18rem;
  }
  .message {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
    // display: none; //暂时隐藏
    .jobLogo {
      width: 7.12rem;
      height: 1.06rem;
      display: inline-block;
      margin: 0.3rem 0;
    }
    ul,
    li {
      padding: 0;
      margin: 0;
      // list-style: none;
      font-size: 0.18rem;
      color: #666666;
    }

    .message_content {
      display: flex;
      justify-content: space-between;
      .message_content_infor {
        width: 100%;
        // background-color: aqua;
        .content_infor_item {
          width: 100%;
          // height: 134px;
          border: 1px solid #e8e8e8;
          padding-bottom: 0.39rem;
          margin-bottom: 0.2rem;
          background-color: #fff;
          .open_details {
            margin-top: 0.2rem;
            margin-right: 0.2rem;
            text-align: right;
            // height: 17px;
            font-size: 0.18rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #004ea2;
            line-height: 0.17rem;
            .open_details_grary {
              color: #999;
            }
          }
          .infor_title {
            height: 25px;
            font-size: 0.28rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #111111;
            line-height: 0.4rem;
            // padding-left: 5.7%;
            // padding-top: 3px;
            padding-left: 0.4rem;
            padding-top: 0.2rem;
          }
          .infor_five {
            display: flex;
            padding-left: 5.7%;
            margin-top: 10px;
            .infor_salary {
              height: 0.2rem;
              // height: 16px;
              font-size: 0.18rem;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #00c3d2;
              line-height: 0.25rem;
              padding-right: 0.16rem;
            }
            .infor_others {
              height: 0.2rem;
              font-size: 0.18rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 0.25rem;
              padding-left: 0.15rem;
              padding-right: 0.15rem;
              border-left: 1px solid rgba(204, 204, 204, 0.5);
            }
          }
        }
        .detail_describe {
          color: #111111;
          font-size: 0.2rem;
          font-weight: bold;
          margin: 20px 0 10px;
        }
        .infor_description_detail {
          font-size: 0.2rem;
          color: #666666;
          line-height: 0.28rem;
          margin-right: 0.4rem;
        }
        // .infor_description_detail {
        //   font-size: 14px;
        //   color: #666;
        //   line-height: 28px;
        //   margin-right: 40px;
        // }
        .apply_job_button {
          width: 1.06rem;
          height: 0.48rem;
          background: #004ea2;
          border-radius: 2px;
          font-size: 0.16rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.48rem;
          text-align: center;
          margin-top: 0.2rem;
          margin-left: -0.25rem;
        }
      }
      .message_content_choose {
        width: 23%;
        background-color: #ffffff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // color: #666666;
        line-height: 22px;
        padding-bottom: 40px;
        border-radius: 2px;
        border: 1px solid #e8e8e8;
        display: none;
        .message_content_choose_item {
          padding-top: 18%;
          padding-left: 18%;
        }
        .choose_post_title {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 20px;
        }
        .checkbox_text {
          margin-top: 10px;
        }
        .checkbox_name {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
          margin-left: 8px;
        }
      }
    }

    .select_content {
      margin: 0 auto 0.2rem;
      width: 80%;
      display: flex;
      justify-content: space-between;
      .select_item {
        width: 30%;
        line-height: 0.22rem;
        font-size: 0.18rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .no_data {
    // font-size: 0.2rem;
    // text-align: center;
    // color: #888;
    // line-height: 0.5rem;
    width: 1.93rem;
    height: 3.24rem;
    display: block;
    margin: 1.8rem auto;
    img {
      width: 100%;
    }
  }
  h4 {
    margin-left: -0.14rem;
  }
}
:after,
:before {
  box-sizing: border-box;
}
