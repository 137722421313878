.select_box {
  position: relative;
  width: 100%;
  // border: 1px solid #e8e8e8;
  border-radius: 4px;
  height: 0.64rem;
  line-height: 0.6rem;
  font-size: 0.22rem;
  color: #666;
  .selected_item {
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px;
    display: inline-flex;
    justify-content: space-between;
    background-color: #fff;
  }
  .options_item {
    position: absolute;
    border-radius: 4px;
    box-sizing: border-box;
    top: 0.7rem;
    left: 0;
    width: 100%;
    padding: 0 0 8px 0;
    color: #444;
    background-color: rgb(252, 248, 248);
    transition: all 800ms ease;
    z-index: 99;
    .option {
      box-sizing: border-box !important;
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
      padding: 2px 10px 0;
      .icon_duigou {
        color: #004ea2;
        font-size: 0.26rem;
      }
    }
  }
}
