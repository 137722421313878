* {
  margin: 0;
  padding: 0;
}

.header {
  background-color: #fff;
  height: 64px;
  line-height: 64px;
}
@media (min-width: 1025px) {
  // 环境待遇
  .environment_treat {
    width: 65%;
    margin: 0 auto;
  }
  .environment_treat_title {
    width: 65%;
    // height: 66px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 10px;
    margin: 0 auto;
  }
  .environment_treat_content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .envirTreatContent_item {
    width: 25%;
    // background-color: cadetblue;
    display: flex;
    // flex-direction: row-reverse;
    // text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  .envirTreatContent_item_circle {
    width: 100px;
    height: 100px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .envirTreatContent_item_circle img {
    width: 40px;
    height: 40px;
  }
  .envirTreatContent_item_text {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    margin-top: 18px;
    // text-align: center;
  }
  .environment_treat_title_two {
    width: 100%;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 40px;
  }
  .threePic {
    display: flex;
    width: 65%;
    margin: 0 auto;
    justify-content: space-around;
  }
  .threePic_item {
    width: 30%;
    // height: 200px;
  }
  .tip {
    width: 100%;
    height: 80px;
    background-color: #ffc769;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 80px;
    margin-top: 71px;
  }
  .threePic_small {
    display: none;
  }
  .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background: rgba(0, 0, 0, 0.8);
    background: #ffc769;
    z-index: 5;
    // display: none;
    .modal_content {
      width: 65%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // background-color: #ffc769;
    }
    .modal_content_close {
      width: 32px;
      height: 32px;
      display: block;
      position: absolute;
      top: 95px;
      right: 25px;
      cursor: pointer;
    }
    .modal_content_title {
      height: 56px;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 56px;
      text-align: center;
    }
    .modal_content_img {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // justify-content: left;
    }
    .modal_content_img img {
      display: block;
      width: 30%;
      margin-left: 20px;
      margin-top: 20px;
    }
  }
}
@media screen and (max-width: 1024px) {
  html {
    // overflow: hidden;
  }
  .environment_treat_box {
    // display: none;
    .environment_treat_title {
      // width: 100%;
      // height: 66px;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.33rem;
      text-align: center;
      padding-top: 0.7rem;
      padding-left: 0.65rem;
      padding-right: 0.65rem;
    }
    .environment_treat {
      width: 100%;
      margin: 0 auto;
      .environment_treat_content {
        width: 94%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
      }
      .envirTreatContent_item {
        width: 25%;
        // background-color: cadetblue;
        display: flex;
        // flex-direction: row-reverse;
        // text-align: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 0.4rem;
      }
      .envirTreatContent_item_circle {
        width: 1rem;
        height: 1rem;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .envirTreatContent_item_circle img {
        width: 0.34rem;
        height: 0.34rem;
      }
      .envirTreatContent_item_text {
        height: 0.22rem;
        font-size: 0.16rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-top: 18px;
        text-align: center;
        // text-align: center;
      }
    }
    .environment_treat_title_two {
      width: 100%;
      // height: 33px;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      // line-height: 33px;
      text-align: center;
      margin-top: 0.8rem;
      margin-bottom: 0.4rem;
    }

    .threePic {
      display: none;
    }
    .threePic_small {
      display: flex;
      width: 100%;
      // margin: 0 auto;
      justify-content: space-around;
    }
    .threePic_item {
      width: 30%;
      // height: 151px;
    }
    .threePic_item img {
      width: 100%;
      height: 100%;
    }
  }
  // .tip {
  //   width: 100%;
  //   // height: 80px;
  //   background-color: #ffc769;
  //   text-align: center;
  //   font-size: 14px;
  //   font-family: PingFangSC-Semibold, PingFang SC;
  //   font-weight: 600;
  //   color: #ffffff;
  //   line-height: 25px;
  //   margin-top: 40px;
  //   padding-top: 28px;
  //   padding-bottom: 27px;
  // }

  .modal {
    position: fixed;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    // background: rgba(0, 0, 0, 0.8);
    background: #ffc769;

    overflow: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 999;
    .modal_content {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // background-color: #ffc769;
    }
    .modal_content_close {
      width: 32px;
      height: 32px;
      display: block;
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }
    .modal_content_title {
      height: 56px;
      font-size: 0.4rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 0.56rem;
      text-align: center;
      margin-top: 40px;
    }
    .modal_content_img {
      width: 100%;
      height: calc(100vh - 170px);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      overflow: scroll;
    }
    .modal_content_img img {
      width: 7.1rem;
      height: 4.73rem;
      display: block;
      // margin-left: 20px;
      margin-top: 20px;
    }
  }
}
