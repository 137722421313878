.page {
  // overflow: auto;
}
.header {
  width: 100%;
  height: 70px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  .header_img {
    background-image: url('../../images/poleStar/logo.png');
    background-size: cover;
    background-position: center center;
    height: 40px;
    width: 219px;
    background-repeat: no-repeat;
    margin-left: 50px;
    margin-top: 15px;
  }
  .header_text {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    padding-right: 40px;
    padding-top: 24px;
    cursor: pointer;
  }
}
.bgContent {
  width: 100%;
  height: 800px;
  background: linear-gradient(180deg, #faffff 2%, #daecff 100%);
  position: relative;
  .bgContent_text {
    width: 33.3%;
    font-size: 42px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #44546a;
    line-height: 59px;
    position: absolute;
    top: 236px;
    left: 9.03%;
    // padding-top: 236px;
    // margin-left: 130px;
  }
  .ball_1 {
    position: absolute;
    width: 163px;
    height: 163px;
    background-image: url('../../images/poleStar/ball_1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: 607px;
    left: 1.32%;
  }
  .ball_2 {
    position: absolute;
    width: 186px;
    height: 180px;
    background-image: url('../../images/poleStar/ball_2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    left: 36.32%;
    top: -12px;
  }
  .ball_3 {
    position: absolute;
    width: 197px;
    height: 188px;
    background-image: url('../../images/poleStar/ball_3.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: 484px;
    right: 2.01%;
  }
  .cloud {
    position: absolute;
    width: 586px;
    height: 447px;
    background-image: url('../../images/poleStar/cloud.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: 144px;
    right: 12.29%;
  }
  .wifi {
    position: absolute;
    width: 181px;
    height: 126px;
    background-image: url('../../images/poleStar/wifi.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: 110px;
    right: 5.97%;
  }
  .pattern {
    position: absolute;
    width: 191px;
    height: 160px;
    background-image: url('../../images/poleStar/type.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    top: 511px;
    right: 52.64%;
  }
  .bgContent_button {
    width: 160px;
    height: 64px;
    background: linear-gradient(180deg, #ffc769 0%, #ffb73e 100%);
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 453px;
    left: 9.03%;
    .bgContent_button_img {
      width: 32px;
      height: 32px;
      background-image: url('../../images/poleStar/message.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .bgContent_button_text {
      width: 72px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 25px;
      margin-left: 4px;
    }
  }
}
.content {
  width: 100%;
  background: #ffffff;
  .content_item {
    width: 92%;
    height: 300px;
    margin: 0 auto;
    // background: rosybrown;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    .content_item_img {
      width: 218px;
      height: 73px;
      margin-left: 61px;
      // background-image: url('../../images/poleStar/Polestar_cun.png');
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .content_item_text {
      width: 63%;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
      margin-right: 60px;
    }
  }
}
.tip {
  width: 100%;
  height: 80px;
  background-color: #ffc769;
  display: flex;
  text-align: center;
  justify-content: center;
  // flex-direction: column;
  align-items: center;
  .tip_one {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 80px;
    margin-right: 20px;
  }

  .tip_two {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 40px;
  }
}
