.singleMoleculeCmp {
  // width: 308px;
  // height: 234px;
  // transform: scale(1.5);
  position: relative;
  opacity: 0.2;

  .circle {
    position: absolute;
  }
  .circle:nth-child(1) {
    display: block;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: 0;
    background: radial-gradient(
      circle at 10px 10px,
      #fff,
      rgb(221, 221, 221),
      rgb(194, 194, 194),
      rgb(211, 211, 211),
      rgb(170, 170, 170)
    );
    /* opacity: 0.35; */
    top: 70px;
    left: 20px;
    -webkit-animation: hover_1 infinite 4s ease-in-out;
    animation: hover_1 infinite 4s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .circle:nth-child(2) {
    display: block;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    margin: 0;
    background: radial-gradient(
      circle at 15px 15px,
      #fff,
      rgb(221, 221, 221),
      rgb(179, 179, 179),
      rgb(211, 211, 211),
      rgb(158, 158, 158)
    );
    /* opacity: 0.35; */
    top: 20px;
    left: 75px;
    -webkit-animation: hover_2 infinite 16s ease-in-out;
    animation: hover_2 infinite 16s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .circle:nth-child(3) {
    display: block;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    margin: 0;
    background: radial-gradient(
      circle at 15px 15px,
      #fff,
      rgb(221, 221, 221),
      rgb(194, 194, 194),
      rgb(211, 211, 211),
      rgb(170, 170, 170)
    );
    /* opacity: 0.35; */
    top: 80px;
    left: 80px;
    -webkit-animation: hover_3 infinite 6s ease-in-out;
    animation: hover_3 infinite 6s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .circle:nth-child(4) {
    display: block;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin: 0;
    background: radial-gradient(
      circle at 15px 15px,
      #fff,
      rgb(221, 221, 221),
      rgb(194, 194, 194),
      rgb(211, 211, 211),
      rgb(170, 170, 170)
    );
    /* opacity: 0.35; */
    top: 62px;
    left: 102px;
    -webkit-animation: hover_4 infinite 10s linear;
    animation: hover_4 infinite 10s linear;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .circle:nth-child(5) {
    display: block;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    margin: 0;
    background: radial-gradient(
      circle at 10px 10px,
      #fff,
      rgb(221, 221, 221),
      rgb(194, 194, 194),
      rgb(211, 211, 211),
      rgb(170, 170, 170)
    );
    /* opacity: 0.35; */
    top: 65px;
    left: 120px;
    -webkit-animation: hover_5 infinite 28s linear;
    animation: hover_5 infinite 28s linear;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .circle:nth-child(6) {
    display: block;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    margin: 0;
    background: radial-gradient(
      circle at 10px 10px,
      #fff,
      rgb(221, 221, 221),
      rgb(194, 194, 194),
      rgb(211, 211, 211),
      rgb(170, 170, 170)
    );
    /* opacity: 0.35; */
    top: 85px;
    left: 150px;
    -webkit-animation: hover_6 infinite 4s ease-in-out;
    animation: hover_6 infinite 4s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .circle:nth-child(7) {
    display: block;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    margin: 0;
    background: radial-gradient(
      circle at 45px 45px,
      #fff,
      rgb(221, 221, 221),
      rgb(177, 177, 177),
      rgb(211, 211, 211),
      rgb(153, 153, 153)
    );
    /* opacity: 0.35; */
    top: 55px;
    left: 168px;
    -webkit-animation: hover_7 infinite 5s ease-in-out;
    animation: hover_7 infinite 5s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .circle:nth-child(8) {
    display: block;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0;
    background: radial-gradient(circle at 12px 12px, #fff, rgb(233, 233, 233), rgb(221, 221, 221), rgb(139, 139, 139));
    /* opacity: 0.35; */
    top: 55px;
    left: 170px;
    -webkit-animation: hover_8 infinite 6s ease-in-out;
    animation: hover_8 infinite 6s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .circle:nth-child(9) {
    display: block;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    margin: 0;
    background: radial-gradient(
      circle at 20px 25px,
      #fff,
      rgb(221, 221, 221),
      rgb(194, 194, 194),
      rgb(211, 211, 211),
      rgb(170, 170, 170)
    );
    /* opacity: 0.35; */
    top: 125px;
    left: 170px;
    -webkit-animation: hover_9 infinite 12s ease-in-out;
    animation: hover_9 infinite 12s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .circle:nth-child(10) {
    display: block;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: 0;
    background: radial-gradient(
      circle at 25px 30px,
      #fff,
      rgb(221, 221, 221),
      rgb(194, 194, 194),
      rgb(211, 211, 211),
      rgb(170, 170, 170)
    );
    /* opacity: 0.35; */
    top: 50px;
    left: 250px;
    -webkit-animation: hover_10 infinite 12s ease-in-out;
    animation: hover_10 infinite 12s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .circle:nth-child(11) {
    display: block;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin: 0;
    background: radial-gradient(
      circle at 25px 30px,
      #fff,
      rgb(221, 221, 221),
      rgb(194, 194, 194),
      rgb(211, 211, 211),
      rgb(170, 170, 170)
    );
    /* opacity: 0.35; */
    top: 155px;
    left: 100px;
    -webkit-animation: hover_11 infinite 8s ease-in-out;
    animation: hover_11 infinite 8s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .circle:nth-child(12) {
    display: block;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin: 0;
    background: radial-gradient(
      circle at 25px 30px,
      #fff,
      rgb(221, 221, 221),
      rgb(194, 194, 194),
      rgb(211, 211, 211),
      rgb(170, 170, 170)
    );
    /* opacity: 0.35; */
    top: 0px;
    left: 230px;
    -webkit-animation: hover_12 infinite 4s ease-in-out;
    animation: hover_12 infinite 4s ease-in-out;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  @-webkit-keyframes hover_1 {
    50% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
    }
    100% {
      -webkit-transform: translatex(10px);
      transform: translatex(10px);
    }
  }
  @keyframes hover_1 {
    50% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
    }
    100% {
      -webkit-transform: translatex(10px);
      transform: translatex(10px);
    }
  }
  @-webkit-keyframes hover_2 {
    0% {
      -webkit-transform: translatex(-20px);
      transform: translatex(-20px);
    }
    50% {
      -webkit-transform: translateY(30px);
      transform: translateY(30px);
    }
    100% {
      -webkit-transform: translatex(-20px);
      transform: translatex(-20px);
    }
  }
  @keyframes hover_2 {
    0% {
      -webkit-transform: translatex(-20px);
      transform: translatex(-20px);
    }
    50% {
      -webkit-transform: translateY(30px);
      transform: translateY(30px);
    }
    100% {
      -webkit-transform: translatex(-20px);
      transform: translatex(-20px);
    }
  }
  @-webkit-keyframes hover_3 {
    50% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
    }
    100% {
      -webkit-transform: translatex(12px);
      transform: translatex(12px);
    }
  }
  @keyframes hover_3 {
    50% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
    }
    100% {
      -webkit-transform: translatex(12px);
      transform: translatex(12px);
    }
  }
  @-webkit-keyframes hover_4 {
    20% {
      -webkit-transform: translateY(3px);
      transform: translateY(3px);
    }
    50% {
      -webkit-transform: translatex(-28px);
      transform: translatex(-28px);
    }
    100% {
      -webkit-transform: translateY(15px);
      transform: translateY(15px);
    }
  }
  @keyframes hover_4 {
    20% {
      -webkit-transform: translateY(3px);
      transform: translateY(3px);
    }
    50% {
      -webkit-transform: translatex(-28px);
      transform: translatex(-28px);
    }
    100% {
      -webkit-transform: translateY(15px);
      transform: translateY(15px);
    }
  }
  @-webkit-keyframes hover_5 {
    0% {
      -webkit-transform: translate(0px, 0px);
      transform: translate(0, 0);
    }
    10% {
      -webkit-transform: translate(30px, -12px);
      transform: translate(30px, -12px);
    }
    20% {
      -webkit-transform: translate(65px, -25px);
      transform: translate(65px, -25px);
    }
    30% {
      -webkit-transform: translate(100px, -40px);
      transform: translate(100px, -40px);
    }
    70% {
      -webkit-transform: translate(120px, 80px);
      transform: translate(120px, 80px);
    }
    100% {
      -webkit-transform: translate(0px, 0px);
      transform: translate(0, 0);
    }
  }
  @keyframes hover_5 {
    0% {
      -webkit-transform: translate(0px, 0px);
      transform: translate(0, 0);
    }
    25% {
      -webkit-transform: translate(70px, 40px);
      transform: translate(70px, 40px);
    }
    50% {
      -webkit-transform: translate(140px, 0);
      transform: translate(140px, 0);
    }
    75% {
      -webkit-transform: translate(70px, -40px);
      transform: translate(70px, -40px);
    }
    100% {
      -webkit-transform: translate(0px, 0px);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes hover_6 {
    50% {
      -webkit-transform: translateY(1px);
      transform: translateY(1px);
    }
    100% {
      -webkit-transform: translatex(0px);
      transform: translatex(0px);
    }
  }
  @keyframes hover_6 {
    50% {
      -webkit-transform: translateY(1px);
      transform: translateY(1px);
    }
    100% {
      -webkit-transform: translatex(0px);
      transform: translatex(0px);
    }
  }
  @-webkit-keyframes hover_7 {
    50% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    100% {
      -webkit-transform: translatex(15px);
      transform: translatex(15px);
    }
  }
  @keyframes hover_7 {
    50% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    100% {
      -webkit-transform: translatex(15px);
      transform: translatex(15px);
    }
  }
  @-webkit-keyframes hover_8 {
    50% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    100% {
      -webkit-transform: translatex(15px);
      transform: translatex(15px);
    }
  }
  @keyframes hover_8 {
    50% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    100% {
      -webkit-transform: translatex(15px);
      transform: translatex(15px);
    }
  }
  @-webkit-keyframes hover_9 {
    50% {
      -webkit-transform: translateY(1px);
      transform: translateY(1px);
    }
    100% {
      -webkit-transform: translatex(0px);
      transform: translatex(0px);
    }
  }
  @keyframes hover_9 {
    0% {
      -webkit-transform: translate(-10px, 1px);
      transform: translate(-10px, 1px);
    }
    50% {
      -webkit-transform: translatex(40px);
      transform: translatex(40px);
    }
    100% {
      -webkit-transform: translate(-10px, 1px);
      transform: translate(-10px, 1px);
    }
  }
  @-webkit-keyframes hover_10 {
    0% {
      -webkit-transform: translate(-10px, -20px);
      transform: translate(-10px, -20px);
    }
    50% {
      -webkit-transform: translatex(40px);
      transform: translatex(40px);
    }
    100% {
      -webkit-transform: translate(-10px, -20px);
      transform: translate(-10px, -20px);
    }
  }
  @keyframes hover_10 {
    0% {
      -webkit-transform: translate(-10px, -20px);
      transform: translate(-10px, -20px);
    }
    50% {
      -webkit-transform: translatex(40px);
      transform: translatex(40px);
    }
    100% {
      -webkit-transform: translate(-10px, -20px);
      transform: translate(-10px, -20px);
    }
  }
  @-webkit-keyframes hover_11 {
    0% {
      -webkit-transform: translate(10px, -30px);
      transform: translate(10px, -30px);
    }
    50% {
      -webkit-transform: translateY(30px);
      transform: translateY(30px);
    }
    100% {
      -webkit-transform: translate(10px, -30px);
      transform: translate(10px, -30px);
    }
  }
  @keyframes hover_11 {
    0% {
      -webkit-transform: translate(10px, -30px);
      transform: translate(10px, -30px);
    }
    50% {
      -webkit-transform: translateY(30px);
      transform: translateY(30px);
    }
    100% {
      -webkit-transform: translate(10px, -30px);
      transform: translate(10px, -30px);
    }
  }
  @-webkit-keyframes hover_12 {
    50% {
      -webkit-transform: translateY(30px);
      transform: translateY(30px);
    }
    100% {
      -webkit-transform: translatex(10px);
      transform: translatex(10px);
    }
  }
  @keyframes hover_12 {
    50% {
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
    }
    100% {
      -webkit-transform: translatex(10px);
      transform: translatex(10px);
    }
  }
}
