@media screen and (max-width: 760px) {
  .PhoneMenuCmp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    color: #fff;
    background: #004ea2;
    margin: 0 auto;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    width: 100%;
    height: 100vh;
    padding: 0 0 24px;
    z-index: 1000;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);

    .PhoneMenuBox {
      .PhoneMenuCloseBtn {
        width: 0.6rem;
        height: 0.6rem;
      }
      .PhoneMenuContent {
        list-style-type: none;
        color: #fff;
        text-align: center;
        font-size: 0.4rem;
        line-height: 1.5rem;
        .PhoneMenu_midLine {
          background: #00c3d2;
          width: 0.7rem;
          height: 0.04rem;
          margin: 0 auto;
          margin-top: 0.4rem;
          margin-bottom: 0.4rem;
        }
      }
    }
  }
}
//- 媒体查询(761px-)
@media screen and(min-width: 761px) {
  .PhoneMenuCmp {
    display: none;
  }
}
