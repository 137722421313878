.homePage {
  :global {
    *,
    :after,
    :before {
      box-sizing: content-box;
    }
  }

  .fade-enter {
    opacity: 0;
    transform: translateY(100%);
  }

  .fade-enter-active {
    opacity: 0.5;
    transform: translateY(0);
    transition: all 5000ms;
  }

  .fade-exit {
    opacity: 1;
    transform: translateX(-100%);
  }

  .fade-exit-active {
    opacity: 0;
    transform: translateY(0);
    transition: all 5000ms;
  }
}
*,
:after,
:before {
  box-sizing: content-box;
}
