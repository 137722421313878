* {
  margin: 0;
  padding: 0;
}

.header {
  background-color: #fff;
  height: 64px;
  line-height: 64px;
}

@media (min-width: 1025px) {
  .title {
    width: 100%;
    height: 430px;
    background-image: url(../../images/job-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
    .text {
      text-align: center;
      color: #ffffff;
      font-size: 32px;
      font-weight: 600;
    }
    .text1 {
      width: 66%;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      text-align: center;
      // padding: 0 42px 0 42px;
      // padding-top: 40px;
      padding-top: 20px;
    }
  }
  .middle_banner {
    width: 100%;
    height: 80px;
    // min-height: 100vh;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    line-height: 80px;
    font-size: 18px;
    font-weight: 400;
    color: #666666;
  }
  .tab_item {
    margin: 0 40px;
    line-height: 25px;
    cursor: pointer;
  }
  .currently_selected {
    color: #004ea2;
    font-size: 18px;
    font-weight: 600;
    margin: 0 40px;
    line-height: 25px;
    cursor: pointer;
  }
  .tab_line {
    color: #ccc;
    line-height: 25px;
  }
  .message {
    width: 65%;
    margin: 0 auto;
    .jobLogo {
      width: 100%;
      height: 140px;
    }
    ul,
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      font-size: 14px;
      color: #666666;
    }

    .message_content {
      display: flex;
      justify-content: space-between;
      .message_content_infor {
        width: 75%;
        // background-color: aqua;
        .content_infor_item {
          width: 100%;
          // height: 134px;
          border: 1px solid #e8e8e8;
          padding-bottom: 40px;
          margin-bottom: 20px;
          .open_details {
            margin-top: 20px;
            margin-right: 40px;
            text-align: right;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #004ea2;
            line-height: 17px;
          }
          .infor_title {
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #111111;
            line-height: 25px;
            padding-left: 5.7%;
            padding-top: 3px;
          }
          .infor_five {
            display: flex;
            padding-left: 5.7%;
            margin-top: 10px;
            .infor_salary {
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #00c3d2;
              line-height: 20px;
              padding-right: 5%;
            }
            .infor_others {
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
              padding-left: 5%;
              padding-right: 5%;
              border-left: 1px solid #cccccc;
            }
          }
        }
        .detail_describe {
          color: #111111;
          font-size: 14px;
          font-weight: bold;
          margin-top: 20px;
        }
        .apply_job_button {
          width: 106px;
          height: 48px;
          background: #004ea2;
          border-radius: 2px;

          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 48px;
          text-align: center;
          margin-top: 20px;
        }
      }
      .message_content_choose {
        width: 23%;
        background-color: #ffffff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // color: #666666;
        line-height: 22px;
        padding-bottom: 40px;
        border-radius: 2px;
        border: 1px solid #e8e8e8;
        .message_content_choose_item {
          padding-top: 18%;
          padding-left: 18%;
        }
        .choose_post_title {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 20px;
        }
        .checkbox_text {
          margin-top: 10px;
        }
        .checkbox_name {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
          margin-left: 8px;
        }
      }
    }
  }

  // 环境待遇

  .environment_treat {
    width: 65%;
    margin: 0 auto;
  }
  .environment_treat_title {
    width: 65%;
    height: 66px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .environment_treat_content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .envirTreatContent_item {
    width: 25%;
    // background-color: cadetblue;
    display: flex;
    // flex-direction: row-reverse;
    // text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  .envirTreatContent_item_circle {
    width: 100px;
    height: 100px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .envirTreatContent_item_circle img {
    width: 40px;
    height: 40px;
  }
  .envirTreatContent_item_text {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    margin-top: 18px;
    // text-align: center;
  }
  .environment_treat_title_two {
    width: 100%;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 33px;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 40px;
  }
  .threePic {
    display: flex;
    width: 65%;
    margin: 0 auto;
    justify-content: space-around;
  }
  .threePic_item {
    width: 32%;
    height: 200px;
  }
  .tip {
    width: 100%;
    height: 80px;
    background-color: #ffc769;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 80px;
    margin-top: 71px;
  }
  .threePic_small {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .title {
    width: 100%;
    height: 3.6rem;
    background-image: url(../../images/jobSmall-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0.7rem;
    .text {
      width: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 0.24rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 0.33rem;
      // background-color: red;
    }
    .text1 {
      // width: 65%;
      font-size: 0.16rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.24rem;
      text-align: center;
      // margin-top: 0.2rem;
      // padding: 0 42px 0 42px;
      // padding: 0 0.42rem 0 0.42rem;
      // padding-top: 0.2rem;
      padding: 0.2rem 0.42rem 0;
    }
  }
  .middle_banner {
    width: 100%;
    height: 0.8rem;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    line-height: 0.8rem;
    font-size: 0.18rem;
    font-weight: 400;
    color: #666666;
  }
  .tab_item {
    margin: 0 40px;
    line-height: 0.25rem;
    font-size: 0.24rem;
    cursor: pointer;
  }
  .currently_selected {
    color: #004ea2;
    font-size: 0.24rem;
    font-weight: 600;
    margin: 0 40px;
    line-height: 0.25rem;
    cursor: pointer;
  }
  .tab_line {
    color: #ccc;
    line-height: 0.24rem;
  }

  .message {
    width: 95%;
    margin: 0 auto;
    // display: none; //暂时隐藏
    .jobLogo {
      width: 7.12rem;
      height: 1.06rem;
    }
    ul,
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      font-size: 14px;
      color: #666666;
    }

    .message_content {
      display: flex;
      justify-content: space-between;
      .message_content_infor {
        width: 100%;
        // background-color: aqua;
        .content_infor_item {
          width: 100%;
          // height: 134px;
          border: 1px solid #e8e8e8;
          padding-bottom: 40px;
          margin-bottom: 20px;
          .open_details {
            margin-top: 20px;
            margin-right: 40px;
            text-align: right;
            height: 17px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #004ea2;
            line-height: 17px;
          }
          .infor_title {
            height: 25px;
            font-size: 18px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #111111;
            line-height: 25px;
            padding-left: 5.7%;
            padding-top: 3px;
          }
          .infor_five {
            display: flex;
            padding-left: 5.7%;
            margin-top: 10px;
            .infor_salary {
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #00c3d2;
              line-height: 20px;
              padding-right: 2%;
            }
            .infor_others {
              height: 20px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
              padding-left: 1%;
              padding-right: 1%;
              border-left: 1px solid #cccccc;
            }
          }
        }
        .detail_describe {
          color: #111111;
          font-size: 14px;
          font-weight: bold;
          margin-top: 20px;
        }
        .apply_job_button {
          width: 106px;
          height: 48px;
          background: #004ea2;
          border-radius: 2px;

          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 48px;
          text-align: center;
          margin-top: 20px;
        }
      }
      .message_content_choose {
        width: 23%;
        background-color: #ffffff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // color: #666666;
        line-height: 22px;
        padding-bottom: 40px;
        border-radius: 2px;
        border: 1px solid #e8e8e8;
        display: none;
        .message_content_choose_item {
          padding-top: 18%;
          padding-left: 18%;
        }
        .choose_post_title {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #111111;
          line-height: 20px;
        }
        .checkbox_text {
          margin-top: 10px;
        }
        .checkbox_name {
          height: 22px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
          margin-left: 8px;
        }
      }
    }
  }
  .environment_treat_box {
    display: none;
    .environment_treat_title {
      width: 65%;
      // height: 66px;
      font-size: 0.12rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 16px;
      text-align: center;
      padding-top: 0.4rem;
      padding-left: 8.8%;
      padding-right: 8.8%;
      padding-bottom: 0.4rem;
    }
    .environment_treat {
      width: 100%;
      margin: 0 auto;
      .environment_treat_content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
      .envirTreatContent_item {
        width: 25%;
        // background-color: cadetblue;
        display: flex;
        // flex-direction: row-reverse;
        // text-align: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
      }
      .envirTreatContent_item_circle {
        width: 80px;
        height: 80px;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      .envirTreatContent_item_circle img {
        width: 35px;
        height: 35px;
      }
      .envirTreatContent_item_text {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-top: 18px;
        text-align: center;
        // text-align: center;
      }
    }
    .environment_treat_title_two {
      width: 100%;
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
      text-align: center;
      margin-top: 80px;
      margin-bottom: 40px;
    }

    .threePic {
      display: none;
    }
    .threePic_small {
      display: flex;
      width: 100%;
      // margin: 0 auto;
      justify-content: space-around;
    }
    .threePic_item {
      width: 33%;
      // height: 151px;
    }
    .threePic_item img {
      width: 100%;
      height: 100%;
    }
  }
  .tip {
    width: 100%;
    // height: 80px;
    background-color: #ffc769;
    text-align: center;
    font-size: 0.18rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 0.25rem;
    margin-top: 40px;
    padding-top: 28px;
    padding-bottom: 27px;
  }
}
