.PageOne {
  justify-content: center;
  align-items: center;

  //- 媒体查询(1-760px)
  @media screen and (max-width: 760px) {
    .backgroundAnimate {
      display: none;
    }
    // padding-top: 35px;
    // min-height: 100vh;
    .bgAnimate {
      display: none;
    }
    .phoneDevc {
      // min-height: 100vh;
      background-size: cover;
      background-position: center center;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .content {
        position: absolute;
        bottom: 0.4rem;
      }
      .title {
        // max-width: 6rem;
        font-size: 0.35rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin: 0 auto;
        text-align: center;
        line-height: 0.48rem;
        padding: 0.235rem 0.52rem 0 0.52rem;
      }
      .line {
        margin: 0 auto;
        margin-top: 0.33rem;
        width: 0.6rem;
        height: 0.04rem;
        background: #00c3d2;
      }
      .userName {
        font-size: 0.32rem;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
        padding-top: 0.5rem;
        line-height: 0.42rem;
      }
      .userContent {
        padding: 0.25rem 0.65rem 0 0.65rem;
        margin: 0 auto;
        font-size: 0.24rem;
        color: #ffffff;
        text-align: center;
        line-height: 0.32rem;
      }
      .userTitle {
        font-size: 0.24rem;
        color: #ffffff;
        text-align: center;
        line-height: 0.35rem;
      }
      .btn_more {
        color: #fff;
        font-size: 0.24rem;
        width: 2rem;
        height: 0.8rem;
        background: #ffc769;
        border-radius: 0.04rem;
        margin: 0 auto;
        text-align: center;
        line-height: 0.8rem;
        cursor: pointer;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
      }
    }
    .padAndPcDevc {
      display: none;
    }
  }
  //- 媒体查询(761px-1440px)
  @media screen and (min-width: 761px) {
    // padding-top: 70px;
    min-height: calc(100vh - 70px);
    .phoneDevc {
      display: none;
    }
  }
  @media screen and (min-width: 761px) and (max-width: 1440px) {
    // padding-top: 70px;
    min-height: calc(100vh - 70px);
    .padAndPcDevc {
      display: flex;
      .leftOutter {
        width: 63%;
        min-height: calc(100vh - 70px);
        display: flex;
        justify-content: center;
        align-items: center;
        .leftInner {
          padding: 0 62px;

          .title {
            margin: 0 auto;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 33px;
          }
          .content {
            margin: 0 auto;
            padding: 24px 0;
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
          }
          .btn_more {
            color: #fff;
            font-size: 14px;
            width: 88px;
            height: 40px;
            background: #ffc769;
            border-radius: 2px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            margin-bottom: 20px;
          }
        }
      }
      .rightOutter {
        min-height: calc(100vh - 70px);
        width: 37%;
        .rightInner {
          position: relative;
          width: 100%;
          min-height: calc(100vh - 70px);
          display: flex;
          .rightInnerBgLeft {
            width: 70%;
            min-height: calc(100vh - 70px);
          }
          .rightInnerBgRight {
            background: #004ea2;
            width: 30%;
            min-height: calc(100vh - 70px);
          }
          .img {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            .blueBox {
              position: absolute;
              bottom: 30px;
              left: -30px;
              width: 228px;
              height: 54px;
              background: rgba(0, 195, 210, 0.8);
              padding: 23px 20px;
              color: #fff;
              font-size: 12px;
              .blueBox_name {
                font-size: 14px;
              }
              .blueBox_title {
                font-size: 12px;
              }
            }
            .pic {
              max-width: 480px;
              max-height: 644px;
              height: calc(100vh - 70px);
              background-image: url('./../../images/pageOne/lsf@2x.png');
              background-size: cover;
              background-position: center center;
            }
          }
        }
      }
      .blueCube {
        background: rgb(0, 78, 162);
        width: 1rem;
        min-height: calc(100vh);
        position: fixed;
        right: 0;
      }
    }
  }
  @media screen and (min-width: 1441px) {
    .padAndPcDevc {
      display: table;
      margin: 0 auto;
      width: 80%;
      .leftOutter {
        // width: 63%;
        // width: 760px;
        width: calc(100% - 480px - 10%);
        // padding: 0px 13% 0 0;
        min-height: calc(100vh - 70px);
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 0 10%;
        .leftInner {
          .title {
            margin: 0 auto;
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
            line-height: 50px;
          }
          .content {
            margin: 0 auto;
            padding: 24px 0;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
          .btn_more {
            color: #fff;
            font-size: 14px;
            width: 88px;
            height: 40px;
            background: #ffc769;
            border-radius: 2px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            margin-bottom: 20px;
          }
        }
      }
      .middleOutter {
        width: 10%;
        min-height: calc(100vh - 70px);
      }
      .rightOutter {
        min-height: calc(100vh - 70px);
        width: 480px;
        // width: 37%;
        .rightInner {
          position: relative;
          width: 100%;
          min-height: calc(100vh - 70px);
          display: flex;
          .img {
            width: calc(100% - 50px);
            display: inline-block;
            text-align: right;
            position: absolute;
            top: 50%;
            right: 50px;
            transform: translateY(-50%);
            .blueBox {
              position: absolute;
              bottom: 95px;
              right: 150px;
              width: 268px;
              height: 68px;
              background: rgba(0, 195, 210, 0.8);
              padding: 30px 20px;
              color: #fff;
              text-align: left;
              .blueBox_name {
                text-align: left;
                font-size: 18px;
              }
              .blueBox_title {
                font-size: 14px;
              }
            }
            .pic {
              display: inline-block;
              min-width: 480px;
              max-width: 560px;
              min-height: 644px;
              // min-height: calc(100vh - 70px);
              background-image: url('./../../images/pageOne/lsf@2x.png');
              background-size: cover;
              background-position: center center;
            }
          }
        }
      }
      .blueCube {
        background: rgb(0, 78, 162);
        width: 2rem;
        min-height: calc(100vh - 70px);
        height: 100vh;
        position: fixed;
        right: 0;
      }
    }
  }
}
//- 媒体查询(1-760px)
@media screen and (max-width: 760px) {
  .backgroundAnimate {
    display: none;
  }
}
