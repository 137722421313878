.headerCmp {
  background: #fff;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

//- 媒体查询(1-760px)
@media screen and (max-width: 760px) {
  /* 获得焦点的时候 鼠标松开时显示的颜色*/
  a:focus {
    text-decoration: none;
    color: black;
  }
  .headerCmp {
    height: 0.7rem;
    line-height: 0.7rem;
    padding: 0 0 0 20px;
    .headerLeft {
      padding: 0.27rem 0;
      .headerLogo {
        background-image: url('../../images/header/logo@2x.png');
        background-size: contain;
        background-position: center center;
        width: 2.2rem;
        height: 0.2rem;
        object-fit: contain;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    .headerRight {
      display: flex;
      .rightBtnBox {
        display: none;
        font-size: 16px;
        padding: 0 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #333333;
        line-height: 70px;
        cursor: pointer;
      }
      .headerIconBox {
        background: #004ea2;
        padding: 0.22rem 0.28rem;
        .headerIcon {
          width: 0.26rem;
          height: 0.3rem;
          background-image: url('../../images/header/menu.svg');
          background-size: 100% 100%;
          background-position: center center;
        }
      }
    }
  }
}
//- 媒体查询(1025px-)
@media screen and(min-width: 761px) {
  .headerCmp {
    height: 70px;
    line-height: 70px;
    padding: 0 0 0 40px;
    .headerLeft {
      padding: 10px 0;
      .headerLogo {
        background-image: url('../../images/header/logo@2x.png');
        background-size: contain;
        // object-fit: contain;
        background-position: center center;
        height: 50px;
        width: 129px;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    .headerRight {
      display: flex;
      .rightBtnBox {
        display: flex;
        .rightBtn {
          font-size: 16px;
          padding: 0 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #333333;
          line-height: 70px;
          cursor: pointer;
        }
      }
      .headerIconBox {
        cursor: pointer;
        padding: 25px 26px;
        background: #44546a;
        margin-left: 20px;
        .headerIcon {
          width: 30px;
          height: 22px;
          background-image: url('../../images/header/message@2x.png');
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }
}
