// **********************************************************************************pc端*********************************************************************
@media (min-width: 1025px) {
  .closeImg {
    width: 32px;
    height: 32px;
    position: fixed;
    top: 89px;
    right: 25px;
    cursor: pointer;
  }
  .page {
    width: 100%;
    min-height: 100vh;
    background-color: #004ea2;
    padding-bottom: 52px;
    box-sizing: border-box i !important;
  }

  .content {
    // width: 90%;
    // margin: 0 auto;
    // text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
  }
  .content_head {
    height: 56px;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 56px;
    padding-top: 30px;
  }
  .content_title {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    margin-top: 10px;
  }
  .content_message {
    // width: 30%;
    // width: 23%;
    // margin: 0 auto;
  }
  .name {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    text-align: left;
    margin-top: 20px;
  }
  .choose_FileName {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    text-align: left;
  }
  .input_name {
    width: 380px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    padding-left: 20px;
    margin-top: 10px;
    display: block;
  }
  input {
    background: none;
    outline: none;
    border: 1px solid #ccc;
    // opacity: 0.5;
  }
  select {
    background: none;
    outline: none;
    width: 380px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    padding-left: 20px;
    margin-top: 10px;
    display: block;
  }
  option {
    color: #000;
  }
  .checkbox_form {
    // display: flex;
    // margin-top: 10px;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    margin-top: 10px;
    line-height: 0.1;
    color: #ffffff;
  }
  .contactUsForm_checkboxItem {
    display: inline-block;
    // width: 50%;
    box-sizing: border-box;
    // margin-bottom: 15px;
    .contactUsForm_checkboxItem_box {
      display: inline-block;
      border: 2px solid rgba(255, 255, 255, 0.5);
      width: 14px;
      height: 14px;
      border-radius: 4px;
      text-align: center;
      line-height: 12px;
      font-size: 16px;
      vertical-align: middle;
      cursor: pointer;
      color: #ffffff;
    }
    .contactUsForm_checkboxItem_text {
      display: inline-block;
      vertical-align: middle;
      line-height: 20px;
      font-size: 14px;
      font-weight: 600;
      margin-right: 32px;
      margin-left: 10px;
    }
  }

  .checkbox_form_item_input {
    display: inline-block;
  }
  .checkbox_name {
    font-size: 14px;
  }

  .upload_resume {
    // display: flex;
    // width: 106px;
    // height: 32px;
    // background: #ffffff;
    // border-radius: 4px;
    // border: 1px solid #dddddd;
    // align-items: center;
    // justify-content: center;
    // font-size: 14px;
    // font-family: PingFangSC-Regular, PingFang SC;
    // font-weight: 400;
    // color: #666666;

    display: inline-block;
    // line-height: 20px;
    position: relative;
    overflow: hidden;
    display: flex;
    width: 106px;
    height: 32px;
    // background: #ffffff;
    border-radius: 4px;
    // border: 1px solid #dddddd;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;

    // display: block;
    .upload_resume_logo {
      width: 14px;
      height: 14px;
    }
    input {
      position: absolute;
      left: 0px;
      top: 0px;
      zoom: 1;
      filter: alpha(opacity=0);
      opacity: 0;
      font-size: 20px;
      margin-left: -240px;
    }
  }
  .small_tip_file {
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    opacity: 0.5;
    text-align: left;
  }
  .send {
    width: 140px;
    height: 40px;
    background: #ffc769;
    border-radius: 2px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin: 0 auto;
    margin-top: 40px;
    // margin-bottom: 52px;
  }

  // input默认值
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    // width: 42px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    opacity: 0.5;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    opacity: 0.5;
    line-height: 20px;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    opacity: 0.5;
    line-height: 20px;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    opacity: 0.5;
    line-height: 20px;
  }
  .file {
    display: inline-block;
    // line-height: 20px;
    position: relative;
    overflow: hidden;
    display: flex;
    width: 106px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dddddd;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    display: none;
  }
  .file input {
    position: absolute;
    left: 0px;
    top: 0px;
    zoom: 1;
    filter: alpha(opacity=0);
    opacity: 0;
    font-size: 20px;
    margin-left: -240px;
  }
  .pc_upload {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .iphone_upload {
    display: none;
  }
  .contactUs_submitRegFailNotice {
    // position: absolute;
    // top: -30px;
    // left: 0;
    // right: 0;
    // text-align: center;
    // color: #00c3d2;
    // font-size: 14px;
    text-align: center;
    color: #00c3d2;
    font-size: 14px;
  }
  .pc_upload .ant-upload-list-text .ant-upload-text-icon .anticon {
    //anticon anticon-paper-clip
    color: #fff !important;
  }
  .pc_upload .ant-upload-list-text .ant-upload-list-item-name {
    //ant-upload-list-item-name
    color: #fff !important;
  }
  .pc_upload .ant-upload-list-item-name {
    color: #fff !important;
  }
}

// *****************************************************************************************iphone************************************************************************************************************************************************************************************************************
@media screen and (max-width: 1024px) {
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }
  .pc_upload {
    display: none;
  }
  .iphone_upload {
    display: block;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .iphone_upload .ant-upload.ant-upload-select.ant-upload-select-text {
    text-align: left !important;
  }
  .ant-upload {
    text-align: left !important;
  }
  .closeImg {
    width: 0.4rem;
    height: 0.4rem;
    position: fixed;
    top: 0.95rem;
    right: 0.25rem;
    cursor: pointer;
  }
  .content_head {
    // height: 28px;
    font-size: 0.4rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 0.28rem;
    text-align: center;
    padding-top: 0.5rem;
  }
  .content_title {
    text-align: center;
    font-size: 0.18rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 0.25rem;
    margin-top: 0.4rem;
    margin-bottom: 0.3rem;
  }
  .content_message {
    width: 80%;
    margin: 0 auto;
    // display: none;
  }
  .name {
    font-size: 0.24rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    text-align: left;
    margin-top: 0.4rem;
  }
  .choose_FileName {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    text-align: left;
    color: #ffffff;
  }
  .input_name {
    // width: 100%;
    // height: 40px;
    // border-radius: 4px;
    // border: 1px solid #ffffff;
    // font-size: 14px;
    // font-family: PingFangSC-Semibold, PingFang SC;
    // font-weight: 600;
    // color: #ffffff;
    // line-height: 20px;
    // padding-left: 20px;
    // margin-top: 10px;
    // display: block;
    // box-sizing: border-box;
    width: 100%;
    height: 0.8rem;
    border-radius: 0.04rem;
    border: 1px solid #ffffff;
    font-size: 0.24rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    /* line-height: 20px; */
    padding-left: 0.2rem;
    margin-top: 0.1rem;
    display: block;
    box-sizing: border-box;
  }
  input {
    background: none;
    outline: none;
    border: 1px solid #ccc;
    // opacity: 0.5;
  }

  .page {
    width: 100%;
    min-width: 320px;
    min-height: 100vh;
    background-color: #004ea2;
    padding-bottom: 52px;
    box-sizing: border-box i !important;
  }

  .content {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding-top: 0.7rem;
  }

  input {
    background: none;
    outline: none;
    border: 1px solid #ccc;
    // display: none;
    // opacity: 0.5;
  }
  select {
    background: none;
    outline: none;
    width: 100%;
    height: 0.8rem;
    border-radius: 0.04rem;
    border: 1px solid #ffffff;
    font-size: 0.24rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    // line-height: 20px;
    padding-left: 0.2rem;
    margin-top: 0.1rem;
    display: block;
    box-sizing: border-box;
    // display: none;
  }
  option {
    color: #000;
  }
  .checkbox_form {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    margin-top: 10px;
    color: #ffffff;
  }

  .contactUsForm_checkboxItem {
    // display: inline-block;
    margin-bottom: 0.15rem;
    width: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .contactUsForm_checkboxItem_box {
      display: inline-block;
      border: 0.04rem solid rgba(255, 255, 255, 0.5);
      width: 0.35rem;
      height: 0.35rem;
      border-radius: 0.04rem;
      text-align: center;
      line-height: 0.28rem;
      font-size: 0.32rem;
      vertical-align: middle;
    }
    .contactUsForm_checkboxItem_text {
      display: inline-block;
      vertical-align: middle;
      line-height: 0.37rem;
      font-size: 0.24rem;
      font-weight: 600;
      margin-left: 0.16rem;
      color: #ffffff;
    }
  }

  .checkbox_form_item {
    width: 50%;
    text-align: left;
    height: 40px;
    line-height: 0.1;
  }
  .checkbox_form_item_input {
    display: inline-block;
  }
  .checkbox_name {
    // width: 56px;
    // height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    // line-height: 20px;
    // margin-right: 40px;
    // display: block;
  }

  .upload_resume {
    display: flex;
    width: 106px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dddddd;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    display: none;
    .upload_resume_logo {
      width: 14px;
      height: 14px;
    }
  }
  .small_tip_file {
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    opacity: 0.5;
    text-align: left;
  }
  .send {
    width: 140px;
    height: 40px;
    background: #ffc769;
    border-radius: 2px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin: 0 auto;
    margin-top: 40px;
    // display: none;
    // margin-bottom: 52px;
  }

  // input默认值
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    // width: 42px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 20px;
    opacity: 0.5;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    opacity: 0.5;
    line-height: 20px;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    opacity: 0.5;
    line-height: 20px;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    opacity: 0.5;
    line-height: 20px;
  }
  .file {
    display: inline-block;
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100px;
    height: 100px;
    // background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dddddd;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .file_add {
    width: 24px;
    height: 24px;
    color: #cccccc;
    font-size: 24px;
  }
  .file input {
    position: absolute;
    left: 0px;
    top: 0px;
    zoom: 1;
    filter: alpha(opacity=0);
    opacity: 0;
    font-size: 20px;
    // margin-left: -240px;
    width: 100px;
    height: 100px;
  }
  .contactUs_submitRegFailNotice {
    text-align: center;
    color: #00c3d2;
    font-size: 0.24rem;
  }
}

.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  outline: 0;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  outline: 0;
}
input:not([type='range']) {
  touch-action: manipulation;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
button,
html [type='button'] {
  -webkit-appearance: button;
}
.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  line-height: 1.5715;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-text-container {
  transition: opacity 0.3s, height 0.3s;
}
[class^='ant-'],
[class*='ant-'],
[class^='ant-'] *,
[class*='ant-'] * {
  box-sizing: border-box;
}
.ant-upload-list-text-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-item-error {
  color: #ff4d4f;
}
.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-text .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text-container .ant-upload-span {
  flex: auto;
}
:global .anticon .anticon-plus {
  color: white !important;
}
:global .ant-btn .ant-btn-icon-only {
  color: white !important;
}
:global .ant-upload-list-item-name {
  color: white;
}

:global .ant-upload-list-text .ant-upload-text-icon .anticon {
  color: white;
}
:global .ant-upload-list-item-info {
  background: transparent !important;
}
:global .ant-upload-list-text .ant-upload-span {
  background: transparent !important;
}
:global .ant-upload-list-item-card-actions .anticon {
  color: white;
}
:global .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
